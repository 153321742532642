.dark-mode {
  --pf-foreground: hsl(187, 77%, 14%);
  --color: hsl(0, 0%, 100%);
}

.judge-0-window {
  width: 1;
  height: 70vh;
  display: flex;
  background-color: var(--pf-foreground);
}

.monaco-editor-window {
  width: 65%;
  height: 100%;
}

.utility-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 35%;
}

.compile-button {
  height: 7%;
  padding: 10px 20px;
  background-color: var(--pf-foreground);
  color: var(--color);
  font-size: 16px; /* Set font size */
  border-radius: 5px; /* Add some rounded corners */
  cursor: pointer; /* Add cursor on hover */
  margin-top: 10px;
  border: 2px solid #000000;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0);
}

.language-dropdown {
  width: 50%;
  height: 10%;
  padding-top: 10px;
  padding-left: 10px;
}

.css-e2pr5d-control {
  background-color: var(--pf-foreground) !important;
}

.css-1dimb5e-singleValue {
  color: var(--color) !important;
}

.output-custom-input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 85%;
  background-color: var(--pf-foreground);
  color: var(--color);
  justify-content: center;
}

.output-window {
  margin-top: -3%;
  height: 60%;
  width: 94%;
  overflow: auto;
  background-color: var(--pf-foreground);
  color: var(--color);
}
.custom-input-window {
  display: inline-block;
  flex-wrap: wrap;
  width: 100%;
}
.text-area {
  justify-content: center;
  color: var(--color) !important;
  background-color: var(--pf-foreground) !important;
}

.metrics-container {
  font-size: x-small;
}
