 .footer-container {
     margin-top: auto
 }

 .container {
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     background-color: #aec2d0;
     width: 100%;
     padding-top: 1rem;
    background-color: var(--navbar-footer-color);
    color: var(--secondary-color);
 }

 .icon {
     background-color: rgb(21, 21, 21);
     max-width: 100%;
     height: auto;
     margin: 0 0.5rem;
     padding: 0.3rem;
     border-radius: 0.3rem;
     color: #2893CC;
 }