.navbar {
    /* padding: 0.5rem; */
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--navbar-footer-color);
    width: 100%;
    margin: 0 auto;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.2rem;
}

/* styling of the navbar links */
a{
    color: var(--secondary-color);
}

.logo-why-container {
    display: flex;
    align-items: center;
}

.logo-container {
    width: 10%;
    padding: 0.5rem;
    margin-left: 5rem;
    margin-right: 2rem;
}

.link-container {
    cursor: pointer;
}

.logo {
    max-width: 100%;
    height: 60px;
    cursor: pointer;
}

.menu-container {
    display: flex;
    align-items: center;
}

select {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    /* color: inherit; */
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    /* other CSS styles... */
}

.theme-btn {
    background-color: inherit;
    color: inherit;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0.5rem;
    margin-right: 5rem;
}

.theme-btn:hover {
    text-decoration: underline;
}