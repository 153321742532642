/* css for the textcopyable area */
.copyable-text-container {
    position: relative;
}

.copyable-text-container__text {
    width: 50rem;
    height: 100px;
    border: 1px solid black;
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.copyable-text-container__copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #0077ff;
    color: white;
    border: none;
    font-family: Arial, sans-serif;
    font-size: 14px;
    cursor: pointer;
}

.copyable-text-container__copy-button:hover {
    background-color: #0055cc;
}