/* @tailwind base;
@tailwind components;
@tailwind utilities;
 */

body {
  /* font-family: 'IBM Plex Sans', sans-serif; */
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
}

.App-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.App-container.light {
  --primary-color: #cedae3;
  --secondary-color: black;
  --navbar-footer-color: #aec2d0;
  --drag-drop-primary-color: var(--pf-foreground);
  --drag-drop-secondary-color: var(--pf-secondary-color);
}
.App-container.dark {
  --primary-color: #253541;
  --secondary-color: white;
  --navbar-footer-color: #2f3541;
  --drag-drop-primary-color: var(--pf-dark-secondary-color);
  --drag-drop-secondary-color: var(--pf-dark-foreground);
}
/* for the contributor section */
.people-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0.5rem;
}
